import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import Card from "components/card";
import { useFormik } from "formik";
import React from "react";
import { set_charge } from "services/merchant.service";

const AddSlab = ({ id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");

  const formik = useFormik({
    initialValues: {
      from_amount: undefined,
      to_amount: undefined,
      value: undefined,
      type: "percentage",
    },
    onSubmit: (values) => {
      set_charge(values, id)
        .then((res) => {
          if (res.statusCode === 201) {
            onClose();
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <>
      <button
        onClick={onOpen}
        className="!linear z-[1] flex items-center justify-center rounded-lg bg-green-500 p-2 text-white !transition !duration-200  active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
      >
        Add Slab
      </button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={scrollBehavior}
      >
        <div
          className="fixed top-0 right-0 left-0 z-50 h-[calc(100%-1rem)] max-h-full  w-full overflow-y-auto overflow-x-hidden md:inset-0"
          style={{ backgroundColor: "rgba(52, 52, 52, 0.8)" }}
        >
          <form
            onSubmit={formik.handleSubmit}
            className="relative flex items-center justify-center  px-5"
          >
            <Card extra="relative p-4 w-full max-w-lg my-10 ">
              <div className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                <h3 className="text-center text-xl font-bold text-navy-700 dark:text-white">
                  Add Slab
                </h3>
                <ModalCloseButton />
              </div>

              <div className="mt-3 grid grid-cols-1">
                <input
                  value={formik.values.from_amount}
                  onChange={formik.handleChange}
                  name="from_amount"
                  type="number"
                  placeholder="From Amount"
                  className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                />
                <input
                  value={formik.values.to_amount}
                  onChange={formik.handleChange}
                  name="to_amount"
                  type="number"
                  placeholder="To Amount"
                  className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                />
                <input
                  value={formik.values.value}
                  onChange={formik.handleChange}
                  name="value"
                  type="number"
                  step="any"
                  placeholder="Charge"
                  className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                />
                <select
                  onChange={formik.handleChange}
                  name="type"
                  value={formik.values.type}
                  className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                >
                  <option
                    value="percentage"
                    className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                  >
                    Percentage
                  </option>
                  <option
                    value="flat"
                    className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                  >
                    Flat
                  </option>
                </select>
              </div>
              <div class="relative mt-5 flex items-center justify-end gap-4 py-4">
                <button
                  onClick={onClose}
                  className="!linear z-[1] flex items-center justify-center rounded-lg bg-red-500 p-2 text-white !transition !duration-200 hover:bg-red-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="!linear z-[1] flex items-center justify-center rounded-lg bg-blue-500 p-2 text-white !transition !duration-200 hover:bg-blue-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                >
                  Add Slab
                </button>
              </div>
            </Card>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddSlab;
