import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import Card from "components/card";
import React, { useEffect, useState } from "react";

import AllTicketTable from "./components/AllTicketTable";
import AddSlab from "./components/AddSlab";
import { get_charge } from "services/merchant.service";

const SetSlab = ({ id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");

  return (
    <>
      <button
        onClick={onOpen}
        className="!linear z-[1] flex items-center justify-center rounded-lg bg-blue-800 p-2 text-white !transition !duration-200 hover:bg-blue-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
      >
        Set Charges
      </button>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={scrollBehavior}
      >
        <div
          className="fixed left-0 right-0 top-0 z-50 h-[calc(100%-1rem)] max-h-full  w-full overflow-y-auto overflow-x-hidden md:inset-0"
          style={{ backgroundColor: "rgba(52, 52, 52, 0.8)" }}
        >
          <div className="relative flex items-center justify-center  px-5">
            <Card extra="relative p-4 w-full max-w-4xl my-10 ">
              <div className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                <AddSlab id={id} />
                <ModalCloseButton />
              </div>
              <AllTicketTable ticketStatus={"ALL"} id={id} />
            </Card>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SetSlab;
