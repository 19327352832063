import { useEffect, useState } from "react";
import CreditReport from "./components/Credit";
import DebitReport from "./components/Debit";
import { getAllMerchants } from "services/merchant.service";

const Report = () => {
  const [merchantList, setMerchantList] = useState([]);
  useEffect(() => {
    getAllMerchants()
      .then((response) => {
        console.log(response);
        setMerchantList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="mt-10 grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
        <DebitReport merchantList={merchantList} />
        <div className="grid grid-cols-1 rounded-[20px]">
          <CreditReport merchantList={merchantList} />
        </div>
      </div>
    </>
  );
};

export default Report;
