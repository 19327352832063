import Card from "components/card";
import InputField from "components/fields/InputField";
import { useFormik } from "formik";
import { useState } from "react";
import { handleDebitDownloadByDate } from "services/report.service";
import { handleDebitDownload } from "services/report.service";


const DebitReport = ({ merchantList }) => {
  const [merchant, setMerchant] = useState();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate:""
    },
    onSubmit: (values) => {
      console.log(values);
      handleDebitDownloadByDate(merchant, values.startDate, values.endDate, setLoading);
    },
  });



  return (
    <Card extra="pb-7 p-[20px]">
      {/* task header */}
      <div className="relative mb-5 flex flex-row justify-between">
        <div className="flex w-full flex-col ">
          <h4 className=" text-xl font-bold text-navy-700 dark:text-white">
            Debit Report
          </h4>

          <select
            onChange={(e) => {
              setMerchant(e.target.value);
            }}
            className="!linear z-[1] mt-5 flex items-center justify-center rounded-lg bg-lightPrimary p-2 px-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          >
            <option
              disabled
              selected
              className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            >
              Select Merchant
            </option>
            {merchantList.map((merchant) => (
              <option
                value={merchant._id}
                className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              >
                {merchant.merchant_name}
              </option>
            ))}
          </select>

          <button
            disabled={loading}
            onClick={()=>handleDebitDownload(merchant,setLoading)}
            className="!linear z-[1] mt-5  flex items-center justify-center rounded-lg bg-blue-500 p-2 text-white !transition !duration-200 hover:bg-blue-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          >
            {loading ? "Downloading..." : "Download All Debit Report"}
          </button>
        </div>
      </div>

      {/* task content */}

      <div className="h-full w-full">
        <div className="mt-5  p-2">
          <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col   gap-2">
          <input
              type="date"
              name="startDate"
              onChange={formik.handleChange}
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            />
            <input
              name="endDate"
              onChange={formik.handleChange}
              
              type="date"
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            />
          </div>
          <div className="flex justify-between">
          

            <button type="submit"  className="!linear z-[1] mt-5 flex items-center justify-center rounded-lg bg-green-500 p-2 text-white !transition !duration-200 hover:bg-green-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
              Download Report
            </button>
          </div>
          </form>
        </div>
      </div>
    </Card>
  );
};

export default DebitReport;
