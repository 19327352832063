import React, { useMemo, useState, useCallback, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import UpdateVPA from "./UpdateVPA";
import { deactivateVPA } from "services/vpa.service";
import { activateVPA } from "services/vpa.service";
import { convertTo24HourUTC } from "utils/date";

createTheme("solarized", {
  background: {
    default: "transparent",
  },
});

function AllTicketTable({ ticketStatus, tableDataComplex }) {
  const [tickets, setTickets] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (ticketStatus === "ALL") {
      setTickets(tableDataComplex);
    } else {
      const filteredData = tableDataComplex.filter(
        (item) => item.status === ticketStatus
      );
      setTickets(filteredData);
    }
  }, [ticketStatus, tableDataComplex]);

  useEffect(() => {
    console.log("state", selectedRows);
  }, [selectedRows]);

  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const columns = useMemo(
    () => [
      {
        name: <TH columnName="NAME" />,
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white" style={{ width: '1500px' }}>
            {row.name}
          </p>
        ),
      },

      {
        name: <TH columnName="VA ID" />,
        selector: (row) => row.vaId,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.vaId}
          </p>
        ),
      },
      {
        name: <TH columnName="BALANCE" />,
        selector: (row) => row.Balance,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.Balance}
          </p>
        ),
      },
      {
        name: <TH columnName="STATUS" />,
        selector: (row) => row.status,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.status}
          </p>
        ),
      },
      {
        name: <TH columnName="ACCOUNT NUMBER" />,
        selector: (row) => row.accounts.account_number,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.accounts[0].account_number}
          </p>
        ),
      },
      {
        name: <TH columnName="IFSC CODE" />,
        selector: (row) => row.accounts[0].ifscCode,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.accounts[0].ifscCode}
          </p>
        ),
      },
      {
        name: <TH columnName="UPI" />,
        selector: (row) => row.accounts[0].upi,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.accounts[0].upi}
          </p>
        ),
      },

      {
        name: <TH columnName="CREATED AT" />,
        selector: (row) => row.createdAt,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {convertTo24HourUTC(row.createdAt)}
          </p>
        ),
      },

      {
        name: <TH columnName="UPDATED AT" />,
        selector: (row) => row.updatedAt,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {convertTo24HourUTC(row.updatedAt)}
          </p>
        ),
      },

      //   {
      //     name: <TH columnName="Action" />,
      //     cell: (row) => {
      //       if (row.status == "ACTIVE") {
      //         return (
      //           <button
      //             onClick={() => handleDeactive(row._id)}
      //             className="!linear z-[1] flex items-center justify-center rounded-lg bg-red-500 p-2 text-white !transition !duration-200  dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
      //           >
      //             De-Activate
      //           </button>
      //         );
      //       } else {
      //         return (
      //           <button
      //             onClick={() => handleActive(row._id)}
      //             className="!linear z-[1] flex items-center justify-center rounded-lg bg-green-500 p-2 text-white !transition !duration-200  dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
      //           >
      //             Activate
      //           </button>
      //         );
      //       }
      //     },
      //     ignoreRowClick: true,
      //     allowOverflow: true,
      //     button: true,
      //   },
    ],
    []
  );

  return (
    <DataTable
      columns={columns}
      data={tickets}
      selectableRows
      onSelectedRowsChange={handleChange}
      pagination
      theme="solarized"
    />
  );
}

export default AllTicketTable;

const TH = ({ columnName }) => {
  const capitalizedColumnName = columnName.toUpperCase();

  return (
    <th className=" pb-[10px] text-start dark:!border-navy-700">
      <p className="text-xs  text-gray-600">{capitalizedColumnName}</p>
    </th>
  );
};
