import React from "react";
import {
  FaWarehouse,
  FaFileInvoice,
  FaWeightScale,
  FaTag,
  FaCalculator,
  FaCreditCard,
  FaIndianRupeeSign,
  FaFileExcel,
  FaPersonCirclePlus,
} from "react-icons/fa6";
import {
  MdCreate,
  MdOutlineViewList,
  MdAccountBalanceWallet,
  MdOutlineSupportAgent,
} from "react-icons/md";
import MainDashboard from "views/admin/default";
import SupportTicket from "views/admin/supportTicket";
import CreditTransactions from "views/admin/creditTransactions";
import DebitTransactions from "views/admin/debitTransactions";
import ViewMerchantDetails from "views/admin/viewMerchantDetails";
import Slab from "views/admin/slab";
import Report from "views/admin/report";
import LedgerPassbook from "views/admin/ledgerPassbook";
import { BsCardImage, BsCreditCard, BsPerson } from "react-icons/bs";
import { LiaTasksSolid } from "react-icons/lia";
import { FaTasks } from "react-icons/fa";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "default",
    component: <MainDashboard />,
  },
  {
    name: "Funds Loaded",
    layout: "/admin",
    path: "creditTransactions",
    icon: <BsCreditCard />,
    component: <CreditTransactions />,
  },
  {
    name: "Payout",
    layout: "/admin",
    path: "debitTransactions",
    icon: < FaIndianRupeeSign/>,
    component: <DebitTransactions />,
  },
  {
    name: "Ledger Passbook",
    layout: "/admin",
    path: "ledgerPassbook",
    icon: <FaTasks />,
    component: <LedgerPassbook />,
  },
  {
    name: "View All Merchants",
    layout: "/admin",
    path: "view-all-merchants",
    icon: <FaPersonCirclePlus />,
    component: <ViewMerchantDetails />,
  },
  {
    name: "Download Reports",
    layout: "/admin",
    path: "reports",
    icon: <FaFileExcel />,
    component: <Report />,
  },
];
export default routes;
