/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useCallback, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { activateVPA, deactivateVPA, createVPA } from "services/vpa.service";
import SetSlab from "views/admin/slab";
import AddIp from "./AddIp";
import { convertTo24HourUTC } from "utils/date";
import FundLoad from "./FundLoad";

createTheme("solarized", {
  background: {
    default: "transparent",
  },
});

function AllTicketTable({ ticketStatus, tableDataComplex }) {
  const [tickets, setTickets] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchTickets = useCallback(() => {
    if (ticketStatus === "ALL") {
      setTickets(tableDataComplex);
    } else {
      const filteredData = tableDataComplex.filter(
        (item) => item.merchant_isVaIdActive === Boolean(Number(ticketStatus))
      );
      setTickets(filteredData);
    }
  }, [ticketStatus, tableDataComplex]);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);



  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleActive = async (id) => {
    try {
      await activateVPA(id);
      alert("Merchant Activated");
      window.location.reload();

    } catch (error) {
      console.error(error);
      alert("Error Activating Merchant");
    }
  };

  const handleDeactive = async (id) => {
    try {
      await deactivateVPA(id);
      alert("Merchant Deactivated");
      window.location.reload();

    } catch (error) {
      console.error(error);
      alert("Error Deactivating Merchant");
    }
  };

  const verifyKYC = async (id) => {
    try {
      await createVPA(id);
      alert("Merchant Wallet Created");
      window.location.reload();

    } catch (error) {
      console.error(error);
      alert("Error Creating Merchant Wallet");
    }
  };

  const columns = useMemo(
    () => [
      {
        name: <TH columnName="Merchant Name" />,
        selector: (row) => row.merchant_name,
        sortable: true,
        cell: (row) => (
          <p
            className="text-sm font-bold text-navy-700 dark:text-white"
            style={{
              whiteSpace: "normal",
              overflow: "visible",
              textOverflow: "ellipsis",
              maxWidth: "500px",
            }}
            title={row.merchant_name}
          >
            {row.merchant_name}
          </p>
        ),
      },
      {
        name: <TH columnName="Merchant SubVA" />,
        selector: (row) => row.merchant_vaId,
        sortable: true,
        cell: (row) => (
          <p
            className="text-sm font-bold text-navy-700 dark:text-white"
            style={{
              whiteSpace: "normal",
              overflow: "visible",
              textOverflow: "ellipsis",
              maxWidth: "500px",
            }}
            title={row.merchant_vaId}
          >
            {row.merchant_vaId}
          </p>
        ),
      },
      {
        name: <TH columnName="Merchant Contact" />,
        selector: (row) => row.merchant_contact,
        sortable: true,
        cell: (row) => (
          <p
            className="text-sm font-bold text-navy-700 dark:text-white"
            style={{
              whiteSpace: "normal",
              overflow: "visible",
              textOverflow: "ellipsis",
              maxWidth: "500px",
            }}
            title={row.merchant_contact}
          >
            {row.merchant_contact}
          </p>
        ),
      },
      {
        name: <TH columnName="Merchant Email" />,
        selector: (row) => row.merchant_email,
        sortable: true,
        cell: (row) => (
          <p
            className="text-sm font-bold text-navy-700 dark:text-white"
            style={{
              whiteSpace: "normal",
              overflow: "visible",
              textOverflow: "ellipsis",
              maxWidth: "500px",
            }}
            title={row.merchant_email}
          >
            {row.merchant_email}
          </p>
        ),
      },
      // {
      //   name: <TH columnName="Merchant State" />,
      //   selector: (row) => row.merchant_state,
      //   sortable: true,
      //   cell: (row) => (
      //     <p
      //       className="text-sm font-bold text-navy-700 dark:text-white"
      //       style={{
      //         whiteSpace: "normal",
      //         overflow: "visible",
      //         textOverflow: "ellipsis",
      //         maxWidth: "500px",
      //       }}
      //       title={row.merchant_state}
      //     >
      //       {row.merchant_state}
      //     </p>
      //   ),
      // },
      {
        name: <TH columnName="Created Date" />,
        selector: (row) => row.merchant_createdAt,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white"
          >
            {convertTo24HourUTC(row.merchant_createdAt)}
          </p>
        ),
      },
      {
        name: <TH columnName="OnBoard" />,
        cell: (row) => {
          return row.merchant_isVaIdActive ? (
            <button
              disabled
              className="z-[1] flex items-center justify-center rounded-lg bg-green-500 p-2 text-white"
            >
              KYC Verified
            </button>
          ) : (
            <button
              onClick={() => verifyKYC(row._id)}
              className="z-[1] flex items-center justify-center rounded-lg bg-red-500 p-2 text-white"
            >
              Verify KYC
            </button>
          );
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: <TH columnName="Status" />,
        cell: (row) => {
          return row.merchant_isActive ? (
            <button
              onClick={() => handleDeactive(row._id)}
              className="z-[1] flex items-center justify-center rounded-lg bg-red-500 p-2 text-white"
            >
              Deactivate
            </button>
          ) : (
            <button
              onClick={() => handleActive(row._id)}
              className="z-[1] flex items-center justify-center rounded-lg bg-blue-500 p-2 text-white"
            >
              Activate
            </button>
          );
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: <TH columnName="Set Charge" />,
        cell: (row) => <SetSlab id={row._id} />,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: <TH columnName="Fund Load" />,
        cell: (row) => <FundLoad id={row._id} />,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    [handleActive, handleDeactive, verifyKYC]
  );

  return (
    <DataTable
      columns={columns}
      data={tickets}
      selectableRows
      onSelectedRowsChange={handleChange}
      pagination
      theme="solarized"
    />
  );
}

export default AllTicketTable;

const TH = ({ columnName }) => (
  <th className="pb-[10px] text-start dark:!border-navy-700">
    <p className="text-xs text-gray-600">{columnName}</p>
  </th>
);
