import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalCloseButton } from "@chakra-ui/modal";
import Card from "components/card";
import { useFormik } from "formik";
import React from "react";
import { createMerchant } from "services/merchant.service";

const AddMerchant = ({ id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");

  const handleCreate = (values) => {
    createMerchant(values)
      .then((response) => {
        console.log(response);
        alert("Merchant Created");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        alert("Merchant Creation Failed");
      });
  };

  const formik = useFormik({
    initialValues: {
      merchant_email: "",
      merchant_name: "",
      merchant_password: "",
      merchant_contact: "",
      merchant_pin: "",
      merchant_city: "",
      merchant_district: "",
      merchant_state: "",
      merchant_address: "",
    },
    onSubmit: (values) => {
      console.log(values);
      handleCreate(values);
    },
  });

  return (
    <>
      <button
        onClick={onOpen}
        className="!linear z-[1] flex items-center justify-center rounded-lg bg-green-500 p-2 text-white !transition !duration-200  dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
      >
        Create Merchant
      </button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={scrollBehavior}
      >
        <div
          className="fixed top-0 right-0 left-0 z-50 h-[calc(100%-1rem)] max-h-full  w-full overflow-y-auto overflow-x-hidden md:inset-0"
          style={{ backgroundColor: "rgba(52, 52, 52, 0.8)" }}
        >
          <div className="relative flex items-center justify-center  px-5">
            <Card extra="relative p-4 w-full max-w-lg my-10 ">
              <div className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                <h3 className="text-center text-xl font-bold text-navy-700 dark:text-white">
                  Enter Merchant Details
                </h3>
                <ModalCloseButton />
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="mt-3 grid grid-cols-1">
                  <input
                    type="text"
                    placeholder="Merchant Email"
                    name="merchant_email"
                    value={formik.values.merchant_email}
                    onChange={formik.handleChange}
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />
                  <input
                    type="text"
                    placeholder="Merchant Name"
                    name="merchant_name"
                    value={formik.values.merchant_name}
                    onChange={formik.handleChange}
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />
                  <input
                    type="password"
                    placeholder="Merchant Password"
                    name="merchant_password"
                    value={formik.values.merchant_password}
                    onChange={formik.handleChange}
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />
                  <input
                    type="text"
                    placeholder="Merchant Contact"
                    name="merchant_contact"
                    value={formik.values.merchant_contact}
                    onChange={formik.handleChange}
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />
                  <input
                    type="text"
                    placeholder="Merchant PIN"
                    name="merchant_pin"
                    value={formik.values.merchant_pin}
                    onChange={formik.handleChange}
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />
                  <input
                    type="text"
                    placeholder="Merchant City"
                    name="merchant_city"
                    value={formik.values.merchant_city}
                    onChange={formik.handleChange}
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />
                  <input
                    type="text"
                    placeholder="Merchant District"
                    name="merchant_district"
                    value={formik.values.merchant_district}
                    onChange={formik.handleChange}
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />
                  <input
                    type="text"
                    placeholder="Merchant State"
                    name="merchant_state"
                    value={formik.values.merchant_state}
                    onChange={formik.handleChange}
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />
                  <input
                    type="text"
                    placeholder="Merchant Address"
                    name="merchant_address"
                    value={formik.values.merchant_address}
                    onChange={formik.handleChange}
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />
                </div>
                <div class="relative mt-5 flex items-center justify-end gap-4 py-4">
                  <button
                    type="button"
                    onClick={onClose}
                    className="!linear ite</div>ms-center z-[1] flex justify-center rounded-lg bg-red-500 p-2 text-white !transition !duration-200 hover:bg-red-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="!linear z-[1] flex items-center justify-center rounded-lg bg-green-500 p-2 text-white !transition !duration-200 hover:bg-blue-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                  >
                    Create Merchant
                  </button>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddMerchant;
