import { useFormik } from "formik";
import InputField from "components/fields/InputField";
import instance from "utils/axios";
import { useCookies } from "react-cookie";
import axios from "axios";
import { BACKEND_URL } from "utils/config";

export default function SignIn() {
  const [cookies, setCookie] = useCookies(["admin_access_token"]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      handleSignIn(values.email, values.password);
    },
  });

  const handleSignIn = (email, password) => {
    if (!email || !password) {
      alert("Please fill all the fields");
      return;
    }
    axios
      .post(`${BACKEND_URL}/auth/login`, { email, password })
      .then((res) => {
        const access_token = res.data.data.accessToken;

        setCookie("admin_access_token", access_token, {
          path: "/",
          expires: new Date(new Date().getTime() + 45 * 60 * 1000),
        });

        alert("Sign In Successful");
        console.log(res.data);
        window.location.href = "/admin";
      })
      .catch((err) => {
        console.log(err.message);
        alert("Sign In Failed");
        console.log("err", err);
      });
  };

  return (
    <div className="flex h-full  w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mx-auto mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In - Admin
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to Sign In!
        </p>

        <form onSubmit={formik.handleSubmit}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@company.com"
            id="email"
            type="text"
            value={formik.values.email}
            onChange={formik.handleChange}
          />

          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />

          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-end px-2">
            <a
              className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              href=" "
            >
              Forgot Password?
            </a>
          </div>
          <button
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            type="submit"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
}
