import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import React from "react";

const CreateTicket = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [scrollBehavior, setScrollBehavior] = React.useState('inside')

    return (
        <>
            {/* <button onClick={onOpen} className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
            Create New Ticket
          </button> */}

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} scrollBehavior={scrollBehavior}>

                <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" style={{backgroundColor: 'rgba(52, 52, 52, 0.8)'}}>

                    <div className="relative flex justify-center items-center  px-5">

                        <Card extra="relative p-4 w-full max-w-lg my-10 ">

                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl text-center font-bold text-navy-700 dark:text-white">
                                    Create New Ticket
                                </h3>
                                <ModalCloseButton />
                            </div>

                            <div className="grid grid-cols-1 mt-3">

                                <select className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none ">
                                <option className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" selected disabled>Select Issue Category</option>
                                    <option className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">Prepaid</option>
                                    <option className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">Prepaid</option>
                                    <option className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">Prepaid</option>
                                </select>

                                <select className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none ">
                                <option className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" selected disabled>Select Issue Sub-Category</option>

                                    <option className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">Prepaid</option>
                                    <option className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">Prepaid</option>
                                    <option className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">Prepaid</option>
                                </select>

                                <input
                                    type="text"
                                    placeholder="Please Enter Subject"
                                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none "
                                />

                                <textarea
                                placeholder="Please Enter Description"
                                rows="2"
                                className="mt-3 flex  w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none ">

                                </textarea>


                            </div>

                            <div class="relative flex items-center justify-end py-4 gap-4 mt-5">

                                <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-red-500 p-2 text-white !transition !duration-200 hover:bg-red-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
                                    Cancel
                                </button>
                                <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-blue-500 p-2 text-white !transition !duration-200 hover:bg-blue-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
                                    Create Ticket
                                </button>
                            </div>
                        </Card>

                    </div>
                </div>


            </Modal>
        </>
    )
}

export default CreateTicket;