import axios from "axios";
import { BACKEND_URL } from "utils/config";

const handleDebitDownload = async (id,setLoading) => {
    try {
        if (!id) {
            return;
        }
        setLoading(true);
      const token = document.cookie.match(/admin_access_token=([^;]+)/)[1];
      const response = await axios({
        url: `${BACKEND_URL}/merchant/get-debit-report/${id}`, // Replace with your server endpoint
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob', // Important
      });

      if (response.status === 200) {
        // Create a new Blob object using the response data of the file
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link to download the blob content
        const fileURL = URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'merchant_debit.xlsx'); // Set the file name here
        document.body.appendChild(fileLink);
        
        fileLink.click(); // Trigger the download

        // Clean up: revoke the object URL and remove the link
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(fileLink);
      }
        setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Download error:', error);
    }
  };




  const handleCreditDownload = async (id,setLoading) => {
    try {
        if (!id) {
            return;
        }
        setLoading(true);
      const token = document.cookie.match(/admin_access_token=([^;]+)/)[1];
      const response = await axios({
        url: `${BACKEND_URL}/merchant/get-credit-report/${id}`, // Replace with your server endpoint
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob', // Important
      });

      if (response.status === 200) {
        // Create a new Blob object using the response data of the file
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link to download the blob content
        const fileURL = URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'credit.xlsx'); // Set the file name here
        document.body.appendChild(fileLink);
        
        fileLink.click(); // Trigger the download

        // Clean up: revoke the object URL and remove the link
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(fileLink);
      }
        setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Download error:', error);
    }
  };



  const handleDebitDownloadByDate = async (id,startDate,endDate,setLoading) => {
    try {
        if (!id) {
            return;
        }
        setLoading(true);
      const token = document.cookie.match(/admin_access_token=([^;]+)/)[1];
      const response = await axios({
        url: `${BACKEND_URL}/merchant/get-debit-report-by-date/${id}`, // Replace with your server endpoint
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          startDate,
          endDate
        },
        responseType: 'blob', // Important
      });
      console.log(response);

      if (response.status === 200) {
        // Create a new Blob object using the response data of the file
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link to download the blob content
        const fileURL = URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'merchant_debit_by_date.xlsx'); // Set the file name here
        document.body.appendChild(fileLink);
        
        fileLink.click(); // Trigger the download

        // Clean up: revoke the object URL and remove the link
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(fileLink);
      }
        setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Download error:', error);
    }
  };



  const handleCreditDownloadByDate = async (id,startDate,endDate,setLoading) => {
    try {
        if (!id) {
            return;
        }
        setLoading(true);
      const token = document.cookie.match(/admin_access_token=([^;]+)/)[1];
      const response = await axios({
        url: `${BACKEND_URL}/merchant/get-credit-report-by-date/${id}`, // Replace with your server endpoint
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data:{
            startDate,
            endDate
        },
        responseType: 'blob', // Important
      });

      if (response.status === 200) {
        // Create a new Blob object using the response data of the file
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link to download the blob content
        const fileURL = URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'merchant_credit_by_date.xlsx'); // Set the file name here
        document.body.appendChild(fileLink);
        
        fileLink.click(); // Trigger the download

        // Clean up: revoke the object URL and remove the link
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(fileLink);
      }
        setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Download error:', error);
    }
  };




  export { handleDebitDownload,handleCreditDownload,handleDebitDownloadByDate,handleCreditDownloadByDate}