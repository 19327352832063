import axios from "axios";
import { BACKEND_URL } from "./config.js";

const instance = axios.create({
  baseURL: BACKEND_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = document.cookie.match(/admin_access_token=([^;]+)/)[1];
    console.log("okkkkkk", token);
    if (token && token != "undefined") {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
