import Card from "components/card";
import { useEffect, useState } from "react";
import AllTicketTable from "./components/AllTicketTable";
import { getCreditTransactions } from "services/merchant.service";


const CreditTransactions = () => {
  const [ticketStatus, setTicketStatus] = useState("ALL");
  const [creditList, setCreditList] = useState([]);
  const [filteredList,setFilterdList]=useState([]);

  useEffect(() => {
    getCreditTransactions()
      .then((response) => {
        console.log(response.data);
        setCreditList(response.data);
        setFilterdList(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const searchAllData = (query) => {
    // Convert query to lowercase for case-insensitive search
    const searchTerm = query.toLowerCase();
    
    // Filter the creditList based on the search term
    const filteredList = creditList.filter(item => {
      // Check if any property of the item contains the search term
      return Object.values(item).some(value => {
        // Convert each value to lowercase for case-insensitive comparison
        return typeof value === 'string' && value.toLowerCase().includes(searchTerm);
      });
    });
  
    // Return the filtered list
   setFilterdList(filteredList)
  };

  return (
    <>
      <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto mt-3"}>
        <div class="relative flex items-center justify-between pt-4">
          <select
            onChange={(e) => setTicketStatus(e.target.value)}
            className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 px-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          >
            <option
              value="ALL"
              className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            >
              All
            </option>
            <option
              value="SUCCESS"
              className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            >
              Success
            </option>
            <option
              value="FAILED"
              className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            >
              Failed
            </option>
            <option
              value="PENDING"
              className="hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            >
              Pending
            </option>
          </select>
          <input
            type="text"
            placeholder="Search..."
            onChange={(e)=>searchAllData(e.target.value)}
            class="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 px-2 text-brand-500 border border-gray-300 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          />

          
        </div>
        <div class="mt-8 overflow-x-scroll xl:overflow-hidden">
          <AllTicketTable
            ticketStatus={ticketStatus}
            tableDataComplex={filteredList}
          />
        </div>
      </Card>
    </>
  );
};

export default CreditTransactions;
