import React, { useMemo, useState, useCallback, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { get_charge } from "services/merchant.service";
import { delete_Slab } from "services/merchant.service";
import { convertTo24HourUTC } from "utils/date";

createTheme("solarized", {
  background: {
    default: "transparent",
  },
});

function AllTicketTable({ ticketStatus, id }) {
  const [tickets, setTickets] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    get_charge(id)
      .then((response) => {
        console.log(response.data);
        setTickets(response.data);
        console.log("tickets", tickets);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    console.log("state", selectedRows);
  }, [selectedRows]);

  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const deleteSlab = (id) => {
    delete_Slab(id)
      .then((response) => {
        console.log(response);
        window.location.reload();
        alert("Slab Deleted Successfully");
      })
      .catch((error) => {
        alert("Error Deleting Slab");
        console.log(error);
      });
  };

  const columns = useMemo(
    () => [
      {
        name: <TH columnName="from_amount" />,
        selector: (row) => row.from_amount,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.from_amount}
          </p>
        ),
      },
      {
        name: <TH columnName="to_amount" />,
        selector: (row) => row.to_amount,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.to_amount}
          </p>
        ),
      },
      {
        name: <TH columnName="value" />,
        selector: (row) => row.value,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.value}
          </p>
        ),
      },
      {
        name: <TH columnName="type" />,
        selector: (row) => row.type,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.type}
          </p>
        ),
      },
      // {
      //   name: <TH columnName="merchant" />,
      //   selector: (row) => row.merchant,
      //   sortable: true,
      //   cell: (row) => (
      //     <p className="text-sm font-bold text-navy-700 dark:text-white">
      //       {row.merchant}
      //     </p>
      //   ),
      // },
      {
        name: <TH columnName="vaId" />,
        selector: (row) => row.vaId,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.vaId}
          </p>
        ),
      },

      {
        name: <TH columnName="createdAt" />,
        selector: (row) => row.createdAt,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {convertTo24HourUTC(row.createdAt)}
          </p>
        ),
      },
      {
        name: <TH columnName="updatedAt" />,
        selector: (row) => row.updatedAt,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {convertTo24HourUTC(row.updatedAt)}
          </p>
        ),
      },
      {
        name: <TH columnName="Action" />,
        selector: (row) => row._id,
        sortable: true,
        cell: (row) => (
          <button
            onClick={() => deleteSlab(row._id)}
            className="!linear z-[1] flex items-center justify-center rounded-lg bg-red-500 p-2 text-white !transition !duration-200  active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          >
            Delete
          </button>
        ),
      },
    ],
    []
  );

  return (
    <DataTable
      columns={columns}
      data={tickets}
      // selectableRows
      // onSelectedRowsChange={handleChange}
      pagination
      theme="solarized"
    />
  );
}

export default AllTicketTable;

const TH = ({ columnName }) => {
  const capitalizedColumnName = columnName.toUpperCase();
  return (
    <th className=" pb-[10px] text-start dark:!border-navy-700">
      <p className="text-xs  text-gray-600">{capitalizedColumnName}</p>
    </th>
  );
};
