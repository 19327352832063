const { default: instance } = require("utils/axios");

const getAllMerchants = async () => {
  const response = await instance.get("/merchant/getMerchantList");
  return response.data;
};

const getDebitTransactions = async () => {
  const response = await instance.get("/merchant/get-debitList");
  return response.data;
};

const getCreditTransactions = async () => {
  const response = await instance.get("/merchant/get-creditList");
  return response.data;
};

const getDashboardData = async () => {
  const response = await instance.get("/merchant/getDashboardData");
  return response.data;
};

const createMerchant = async (values) => {
  const response = await instance.post("/merchant/create-merchant", values);
  return response.data;
};

const updateIp = async (IPAddress, id) => {
  const response = await instance.post(`/merchant/updateIP/${id}`, {
    IPAddress,
  });
  return response.data;
};

const set_charge = async (body, id) => {
  const response = await instance.post(`/merchant/set-charge/${id}`, body);
  return response.data;
};

const get_charge = async (id) => {
  const response = await instance.get(`/merchant/get-charge/${id}`);
  return response.data;
};

const delete_Slab = async (id) => {
  const response = await instance.delete(`/merchant/delete-charge/${id}`);
  return response.data;
};

const getDebitReport = async (id) => {
  const response = await instance.get(`/merchant/get-debit-report/${id}`);
  return response.data;
};

const getCreditReport = async (id) => {
  const response = await instance.get(`/merchant/get-credit-report/${id}`);
  return response.data;
};

const getDebitReportByData = async (id, date) => {
  const response = await instance.post(
    `/merchant/get-debit-report-by-date/${id}`,
    date
  );
  return response.data;
};
const getCreditReportByData = async (id, date) => {
  const response = await instance.post(
    `/merchant/get-debit-report-by-date/${id}`,
    date
  );
  return response.data;
};


const getLedgerReport = async () => {
  const response = await instance.get("/merchant/get-ledger");
  return response.data;
}

const loadFund = async (id, body) => {
  const response = await instance.post(`/merchant/load-fund/${id}`, body);
  console.log(response);

  return response.data;
};




export {
  loadFund,
  getAllMerchants,
  getDebitTransactions,
  getCreditTransactions,
  getDashboardData,
  createMerchant,
  updateIp,
  set_charge,
  delete_Slab,
  get_charge,
  getDebitReport,
  getCreditReport,
  getDebitReportByData,
  getCreditReportByData,
  getLedgerReport
};
