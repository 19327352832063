const { default: instance } = require("utils/axios");

const getAllVPAs = async () => {
  // const response = await instance.get("/subVA/listSubVA");
  return {};
};

const activateVPA = async (id) => {
  const response = await instance.get(
    `/merchant/activateMerchant/${id}`
  );
  return response.data;
};

const deactivateVPA = async (id) => {
  const response = await instance.get(`/merchant/deactivateMerchant/${id}`);
  return response.data;
};

const createVPA = async (id) => {
  const response = await instance.get(`/merchant/createSubVA/${id}`);
  return response.data;
};

export { getAllVPAs, activateVPA, deactivateVPA, createVPA };
