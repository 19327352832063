import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import React, { useState } from "react";
import { loadFund } from "services/merchant.service"; // Assuming this service is defined elsewhere

const FundLoad = ({id}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [scrollBehavior, setScrollBehavior] = React.useState('inside')
    const [formData, setFormData] = useState({
      amount: "",
      type: "",
      utr: "",
      remiter_name: "",
      remiter_account_number: "",
      remiter_ifsc: "",
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        try {
            const response = await loadFund(id,formData);
            console.log(response); // Handle the response as needed
            onClose(); // Close the modal after successful submission
        } catch (error) {
            console.error(error); // Handle the error as needed
        }
    };

    return (
      <>
        <button
          onClick={onOpen}
          className="!linear z-[1] flex items-center justify-center rounded-lg bg-blue-800 p-2 text-white !transition !duration-200 hover:bg-blue-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
        >
          Fund Load
        </button>

        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
          scrollBehavior={scrollBehavior}
        >
          <div
            className="fixed left-0 right-0 top-0 z-50 h-[calc(100%-1rem)] max-h-full  w-full overflow-y-auto overflow-x-hidden md:inset-0"
            style={{ backgroundColor: "rgba(52, 52, 52, 0.8)" }}
          >
            <div className="relative flex items-center justify-center  px-5">
              <Card extra="relative p-4 w-full max-w-lg my-10 ">
                <div className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                  <h3 className="text-center text-xl font-bold text-navy-700 dark:text-white">
                    Fund Load
                  </h3>
                  <ModalCloseButton />
                </div>

                <div className="mt-3 grid grid-cols-1">
                  <input
                    type="text"
                    name="amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                    placeholder="Amount"
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />

                  <select
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  >
                    <option value="">Select Type</option>
                    <option value="add">Add</option>
                    <option value="deduct">Deduct</option>
                  </select>

                  <input
                    type="text"
                    name="utr"
                    value={formData.utr}
                    onChange={handleInputChange}
                    placeholder="UTR"
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />

                  <input
                    type="text"
                    name="remiter_name"
                    value={formData.remiter_name}
                    onChange={handleInputChange}
                    placeholder="Remitter's Name"
                    required
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />

                  <input
                    type="text"
                    name="remiter_account_number"
                    value={formData.remiter_account_number}
                    onChange={handleInputChange}
                    placeholder="Remitter's Account Number"
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />

                  <input
                    type="text"
                    name="remiter_ifsc"
                    value={formData.remiter_ifsc}
                    onChange={handleInputChange}
                    placeholder="Remitter's IFSC"
                    className="mt-3 flex h-10 w-full rounded-xl border-2 bg-white/0 px-3 py-2 text-sm outline-none"
                  />
                </div>
                <div class="relative mt-5 flex items-center justify-end gap-4 py-4">
                  <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-red-500 p-2 text-white !transition !duration-200 hover:bg-red-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
                    Cancel
                  </button>
                  <button
                    onClick={handleSubmit}
                    className="!linear z-[1] flex items-center justify-center rounded-lg bg-blue-500 p-2 text-white !transition !duration-200 hover:bg-blue-400 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                  >
                    Submit
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </Modal>
      </>
    );
}

export default FundLoad;